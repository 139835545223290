import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { useAuthenticate } from "../../context/AuthContext";
import { RotatingLines } from "react-loader-spinner";
import { api_url } from "../../utils/data";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import PaystackPop from "@paystack/inline-js";

const SubscriptionPurchase = () => {
  const { package_id } = useParams();
  const { authToken, packages, pharmacyDetails, user } = useAuthenticate();
  const [subscriptionData, setSubscriptionData] = useState({});
  const [amount, setAmount] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [verifingCoupon, setVerifingCoupon] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponVerified, setCouponVerified] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paystackPrice, setPaystackPrice] = useState(0);
  const [subscribing, setSubscribing] = useState(false);
  const [email, setEmail] = useState("adamsmichael46@gmail.com");

  const publicKey = "pk_live_9a4bc46b082a74817a3c8449b541ac99adfc227b";
  // const publicKey = "pk_test_b280bd842436239d7059d65481bd50c960db4302";

  const config = {
    reference: new Date().getTime().toString(),
    email,
    amount: paystackPrice,
    currency: "GHS",
    publicKey,
  };
  const initializePayment = usePaystackPayment(config);

  const makePayment = () => {
    if (email === "") {
      toast.error("Please enter a valid email address");
    } else {
      let handler = PaystackPop.setup({
        key: publicKey,
        reference: new Date().getTime().toString(),
        email: user?.email ? user?.email : "support@rxultimate.com",
        amount: paystackPrice,
        currency: "GHS",
        onClose: () =>
          toast.error("You need to make payment before proceeding."),
        onSuccess: (response) => onSuccess(response),
      });
      handler.openIframe();
    }
  };

  // AFTER SUCCESSFUL PAYMENT
  const onSuccess = (response) => {
    subscriptionData["transaction_id"] = response["reference"];
    subscriptionData["payment_method"] = paymentMethod;
    subscriptionData["amount_paid"] = paystackPrice;
    subscribePharmacy();
  };

  // you can call this function anything
  const onClose = () => {
    toast.error(
      "You need to make payment first before we can proceed with your application"
    );
    console.log("closed");
  };

  let today = new Date().toLocaleDateString();
  var expriyDate = new Date();
  expriyDate.setDate(expriyDate.getDate() + 30);
  expriyDate = expriyDate.toLocaleDateString();

  const handleSubscriptionFormChange = (e) => {
    setSubscriptionData({
      ...subscriptionData,
      [e.target.name]: e.target.value,
    });
  };

  const getSelectedPackage = (package_id) => {
    const filteredPackage = packages.filter((packageItem) => {
      return parseInt(package_id) === parseInt(packageItem.id);
    });
    setSelectedPackage(filteredPackage[0]);
    setAmount(filteredPackage[0]?.price);

    setPaystackPrice(filteredPackage[0]?.price * 100);
  };

  const subscribePharmacy = async () => {
    // setSubscribing(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    subscriptionData["pharmacy"] = pharmacyDetails?.id;
    subscriptionData["package"] = package_id;

    const body = JSON.stringify(subscriptionData);

    await axios
      .post(`${api_url}/subscription/`, body, config)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            title: "Created!",
            text: "Subscription was successful, enjoy RxUltimate.",
            icon: "success",
          }).then(() => {
            window.location.href = "/dashboard";
          });
        } else {
          toast.error(`${response?.data?.message}`);
        }
        setSubscribing(false);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error!",
          text: "Subscription failed!",
          icon: "error",
        });
        setSubscribing(false);
      });
  };

  const verifyCoupon = async () => {
    if (couponCode === "") {
      toast.error("Please enter a coupon code");
      return false;
    } 
    if(couponVerified) {
      toast.error("Coupon already verified");
      return false;
    }
    setVerifingCoupon(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    await axios
      .get(`${api_url}/subscription/verify-coupon/${couponCode}/`, config)
      .then((response) => {
        if (response?.data?.success) {
          setCoupon(response?.data?.coupon_id);
          let discount = parseFloat((parseFloat(response?.data?.discount_percent) / 100) * parseFloat(amount)).toFixed(2);
          let new_amount = parseFloat(amount) - parseFloat(discount);
          setAmount(new_amount);
          setPaystackPrice(new_amount * 100);
          setCouponVerified(true);
          toast.success("Coupon verified successfully");
        } else {
          toast.error(`${response?.data?.message}`);
        }
        setVerifingCoupon(false);
      })
      .catch((error) => {
        setVerifingCoupon(false);
      });
  };

  useEffect(() => {
    getSelectedPackage(package_id);
  }, []);

  return (
    <main id="content" role="main" className="main main-page pointer-event">
      <div className="content container-fluid">
        <div className="page-header">
          <h1 className="mb-0 page-header-title">
            <span className="">Subscription Purchase</span>
          </h1>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div>
              <h3 style={{ marginBottom: 0 }}>Plan Details</h3>
              <p>The plan you wish to subscribe to</p>
            </div>
            <div
              className="current-plan"
              style={{ border: "1px solid rgb(0, 102, 245)" }}
            >
              <div className="current_plan-header">{selectedPackage?.name}</div>
              <div className="current_plan-details">
                <div className="current_plan-price">
                  <h3>
                    GHS {amount}
                    <span>/month</span>
                  </h3>
                </div>
              </div>
              <div
                className="current_plan-footer"
                style={{ background: "rgb(223, 232, 247)" }}
              >
                <div>
                  <p>
                    <b>Registration Date: </b> {today}
                  </p>
                  <p>
                    <b>Expiry Date:</b> {expriyDate}
                  </p>
                </div>
              </div>
              <div className="flex items-center p-3 bg-blue-200">
                <input
                  type="text"
                  disabled={couponVerified}
                  className="border border-slate-500 p-2 uppercase"
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                {verifingCoupon ? (
                  <div className="px-2 py-2 bg-blue-400">
                    <RotatingLines
                      visible={true}
                      height="20"
                      width="20"
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : (
                  <button
                    className="btn btn--primary"
                    onClick={() => verifyCoupon()}
                  >
                    Apply Coupon
                  </button>
                )}
              </div>
            </div>
            <br />
            {selectedPackage?.package_type === "Free" && (
              <button
                onClick={() => subscribePharmacy()}
                className="btn btn--primary"
              >
                Subscribe
              </button>
            )}
          </div>
          {selectedPackage?.package_type !== "Free" && (
            <div className="col-lg-8">
              <h3>Payment Method</h3>
              <p>Select your payment method</p>
              <div className="d-flex" style={{ gap: "10px" }}>
                <div>
                  <label
                    onClick={() => setPaymentMethod("Paystack")}
                    htmlFor="paystack"
                    className={`radio-button ${
                      paymentMethod === "Paystack"
                        ? "active-radio-button"
                        : "border-[#cecece] bg-[white] text-black"
                    }`}
                  >
                    <input
                      type="radio"
                      id="paystack"
                      name="button"
                      value="Paystack"
                      checked={paymentMethod === "Paystack"}
                    />
                    <span>Paystack</span>
                  </label>
                </div>
              </div>
              {paymentMethod === "Paystack" && (
                <button
                  onClick={() => makePayment()}
                  className="btn btn--primary"
                >
                  Make Payment
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default SubscriptionPurchase;
